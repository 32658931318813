<!-- 公共直播 -->
<template>
  <div class="commonLive">
    <div class="header">
      <!-- <img src="@/assets/img/dhlgdx.png" alt="" srcset=""> -->
      <img v-if="schoolInfo.logoUrl" :src="schoolInfo.logoUrl" alt="" srcset="">
    </div>
    <div class="main">
      <div class="videoBox" ref="videoBox" :style="[{ 'height': imgH != 'auto' ? imgH : 'auto ' }]">
        <template v-if="videoInfo.source&&videoInfo.isLive&&status == 1 || status == 2 && liveDetail.playbackUrl">
          <commonPlay ref="commonPlay" v-if="videoInfo.source&&status == 2||videoInfo.source&&videoInfo.isLive&&status == 1" :item="videoInfo"></commonPlay>
          <img :height="imgH != 'auto' ? imgH : 'auto'" v-else :src="liveDetail.coverUrl" alt="" srcset="">
        </template>
        <div class="videoImg" v-else>
          <img :height="imgH != 'auto' ? imgH : 'auto'" v-if="status == 0" :src="liveDetail.coverUrl || liveBG" alt="" srcset="">
          <template v-else-if="status == 2">
            <img :height="imgH != 'auto' ? imgH : 'auto'" v-if="status == 2" :src="liveDetail.coverUrl || liveBG" alt=""
              srcset="">
            <div class="dialog" :style="[{ 'height': imgH+'px' }]">
              <div>已结束</div>
            </div>
          </template>
        </div>
      </div>
      <div class="mainBox">
        <div class="mainHead">
          <template name="icon">
            <!-- <img v-if="status==1" src="@/assets/img/dhlg_live1.png" alt="" srcset="">
            <img v-else-if="status==2" src="@/assets/img/dhlg_live2.png" alt="" srcset="">
            <img v-else src="@/assets/img/dhlgdx_0.png" alt="" srcset=""> -->
            <div class="titleTips" :class="[{ 'blue': status == 0, 'red': status == 1 }]" v-if="status == 0 || status == 1">
              {{ status == 0 ? '未开始' : '直播中' }}</div>
            <!-- <div class="titleTips" :class="[{'blue':status==1}]" v-else-if="status==2">直播中</div> -->
          </template>
          {{ liveDetail.title }}
        </div>
        <div class="mainTime">
          <div class="timeTitle">
            <img src="@/assets/img/dhlgdx_2.png" alt="">直播时间段
          </div>
          <div class="timeMain">
            <span>{{ $way.timestampReturnDate(liveDetail.planStartTime, 'timeYMDHM') }} —
              {{ $way.timestampReturnDate(liveDetail.planEndTime, 'timeYMDHM') }}</span>
            <span>预计{{ liveDetail.liveTime }}分钟</span>
          </div>
        </div>
        <div class="info" v-if="liveDetail.teacherName">
          <div class="infoTitle"><img src="@/assets/img/live/liveTeacher.png" alt="" srcset="" />讲师介绍</div>
          <div class="infoMain teacher">
            <div class="infoT">
              <img class="infoTitleIcon" src="@/assets/img/live/teacherIcon.png"
                alt=""><span>{{ liveDetail.teacherName }}</span>
            </div>
            <div class="line" v-if="liveDetail.teacherIntroduction"></div>
            <div class="infoM" v-if="liveDetail.teacherIntroduction" v-html="liveDetail.teacherIntroduction"></div>
          </div>
        </div>
        <div class="line"></div>
        <div class="info">
          <div class="infoTitle"><img src="@/assets/img/live/liveInfoIcon.png" alt="" srcset="">直播介绍</div>
          <div class="infoMain" v-if="liveDetail.about" v-html="liveDetail.about"></div>
        </div>
        <div class="footer">
          <!-- <div>© 2024-2027 广州传知汇科技有限公司</div> -->
          <div>备案号：粤ICP备2024264514号</div>
          <!-- <div class="imgDiv"><a target="_blank"
              href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44010602010088"><img
                src="@/assets/img/filing.png" alt=""> 粤公网安备 44010602010088号</a></div>
          <div>教APP备4401799号</div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import commonPlay from '@/components/commonModule/upload/commonPlay'
export default {
  name: 'commonLive',
  components: {
    commonPlay
  },
  data () {
    return {
      liveBG: require('@/assets/img/live/videoBG.png'),
      showVideo: false,
      status: 0,
      dateStr: '',
      coverUrl: '',
      imgH: 'auto',
      videoInfo: {
        id: 'dhvideo',
        source: '',
        height: '25vh',
        autoplay: true,
        isLive: true
      },
      schoolInfo: {},
      liveDetail: {}
    }
  },
  created () {
    // document.title = '这是标题';
    const query = this.$route.query
    console.log(location, 'location')
    console.log(this.isPhone(), 'this.isPhone')
    // if (this.isPhone()) {
    //   console.log('shouji');
    //   let hostname = location.hostname
    //   window.location.replace(location.origin + '/' + location.hash)
    //   // window.location.replace(location.origin + '/#/commonLive')
    //   console.log(location.origin + '/#/commonLive', 'window.location')
    // } else {
    //   console.log('非手机');
    //   window.location.replace(location.origin + '/student/' + location.hash)
    //   // window.location.replace(location.origin + '/' + location.hash)
    // }
    this.getSchool()
    this.getCommonLiveDetail()
    this.getCommonLive()
    this.setInter = setInterval(() => {
      this.getCommonLive()
    }, 20000)
  },
  mounted () {
    this.$nextTick(() => {
      console.log(this.$refs.videoBox.clientWidth, this.$refs.videoBox.clientWidth / 16 * 9)
      this.imgH = this.$refs.videoBox.clientWidth / 16 * 9
      if (this.$refs.commonPlay && this.$refs.commonPlay.playSetting) {
        this.$refs.commonPlay.playSetting.height = this.imgH + 'px'
        console.log(this.$refs.commonPlay.playSetting, '')
      }
    })
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {

    })
  },
  beforeDestro () {
    clearInterval(this.setInter)
  },
  methods: {
    isPhone () {
      // return /Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)
      return /Android|webOS| iPhone | iPad | iPod |BlackBerry|opera mini|opera mobile|appleWebkit.*mobile|mobile/i.test(navigator.userAgent)
    },
    getSchool () {
      const hostname = location.hostname
      // const hostname = 'woo.gf5184.com'
      const href = hostname.split('.')[0]
      const params = {
        encoding: href || 'yhdx'
      }
      this.$request.getSchoolInfo(params).then((res) => {
        if (res.data.code == 0) {
          console.log(res.data.data)
          this.schoolInfo = res.data.data
        }
      }).catch((err) => { })
    },
    // 获取直播详情
    getCommonLiveDetail () {
      this.$request.getCommonLiveDetail({ id: this.$route.query.id }).then(res => {
        if (res.data.code == 0) {
          this.liveDetail = res.data.data
          if (res.data.data.status == 2 && this.liveDetail.playbackUrl) {
            this.videoInfo.source = null
            // this.videoInfo.isLive = false
            this.$set(this.videoInfo, 'isLive', false)
            this.videoInfo.source = res.data.data.playbackUrl
            console.log(this.$refs.commonPlay)
          } else {
            this.liveDetail = res.data.data
            // this.videoInfo.isLive = true
            this.$set(this.videoInfo, 'isLive', true)
            this.videoInfo.source = res.data.data.pullLiveUrl
          }
          this.coverUrl = res.data.data.coverUrl
          this.$nextTick(() => {
            if (this.$refs.commonPlay) {
              this.$refs.commonPlay.playSetting.height = this.imgH + 'px'
              console.log(this.$refs.commonPlay.playSetting, 'playSetting')
            }
          })
        }
      })
    },
    // 获取拉流状态变化
    getCommonLive () {
      this.$request.getCommonLiveStatus({ id: this.$route.query.id }).then(res => {
        if (res.data.code == 0) {
          if (this.status !== res.data.data) {
            this.getCommonLiveDetail()
          }
          this.status = res.data.data
        }
      })
    }
  }
}
</script>

<style lang='scss' scoped>
.commonLive {
  height: 100vh;
  overflow-y: scroll;

  img {
    width: 100%;
  }

  .header {
    width: 100%;
    // height: 44px;
    padding: 10px 0;
    background: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 233px;
      height: 26px;
    }
  }

  .main {
    .videoBox {
      height: 25vh;

      commonplay {}

      .videoImg {
        position: relative;

        img {
          width: 100%;
        }

        .dialog {
          position: absolute;
          top: 0;
          background: #000000ad;
          width: 100%;
          // height: -webkit-fill-available;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 20px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 28px;
          z-index: 55;
        }
      }
    }

    .mainBox {
      .mainHead {
        padding: 18px 18px 26px 18px;
        background: #FFFFFF;
        font-size: 20px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 32px;
        display: flex;
        align-items: baseline;

        .titleTips {
          padding: 3px 6px;
          border-radius: 10px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          // color: #1A7EE2;
          line-height: 14px;
          margin-right: 8px;

          &.blue {
            color: #1A7EE2;
            background: rgba(26, 126, 226, 0.1);
            border: 1px solid #1A7EE2;
          }

          &.red {
            color: #FF2D54;
            border: none;
            background: rgba(255, 45, 84, 0.1);
          }
        }

        img {
          width: 54px;
          height: 20px;
          margin: 6px 10px 0 0;
        }
      }

      .mainTime {
        padding: 0 24px 24px;
        background: #FFFFFF;

        .timeTitle {
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
          line-height: 14px;
          display: flex;
          align-items: center;

          img {
            width: 18px;
            height: 18px;
            margin-right: 11px;
          }
        }

        .timeMain {
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          line-height: 12px;
          margin-top: 12px;

          span:nth-child(1) {
            margin-right: 10px;
          }
        }
      }

      .line {
        height: 12px;
        background: #F9F9F9;
      }

      .info {
        position: relative;

        .infoTitle {
          display: inline-flex;
          align-items: center;
          height: 18px;
          font-size: 18px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 600;
          color: #333333;
          line-height: 18px;
          margin: 24px 24px 18px;

          span {
            display: block;
            width: 2px;
            height: 15px;
            background: #1A7EE2;
            border-radius: 15px;
            margin-right: 10px;
          }

          img {
            height: 17px;
            width: 17px;
            margin-right: 6px;
          }
        }

        .infoMain {
          font-size: 15px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #363840;
          line-height: 24px;
          margin: 0 24px 24px;
          word-break: break-all;

          // text-indent: 30px;
          &.teacher {
            background: #F9F9F9;
            border-radius: 10px;
            padding: 16px;
            text-indent: 30px;
          }

          .line {
            margin-bottom: 11px;
            height: 1px;
            background: #EBEBEB;
          }

          .infoT {
            display: flex;
            align-items: center;
            padding-bottom: 9px;

            // border-bottom: 1px solid #EBEBEB;
            .infoTitleIcon {
              background: #FFFFFF;
              width: 44px;
              height: 44px;
              border-radius: 50%;
              overflow: hidden;
              margin-right: 10px;
            }

            span {
              text-indent: 0;
            }
          }

          .infoM {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #363840;
            line-height: 30px;
            word-break: break-all;
          }

          ::v-deep img {
            max-width: 100%;
          }
        }

        .img {
          height: 225px;
          width: 100%;
          position: absolute;
          z-index: -20;
          bottom: -150px;

          img {
            height: 100%;
          }
        }
      }

      .footer {
        color: #FFFFFF;
        background: #27282C;
        padding: 24px 0;
        text-align: center;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
        line-height: 16px;

        div {
          margin-bottom: 14px;

          &:last-child {
            margin-bottom: 0;
          }
        }

        .imgDiv a {
          color: #999999;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            height: 20px;
            width: 18px;
            margin-right: 10px;
          }
        }
      }
    }
  }
}</style>
