<!-- 通用图片点击放大 -->
<template>
  <div :class="['commonPlay']">
    <div v-if="itemChild.id" :id="'avideo'+itemChild.id" class="prism-player" />
  </div>
</template>

<script>
export default {
  name: 'commonImg',
  props: {
    item: {
      props: Object,
    },
    setting: {
      props: Object,
    },
  },
  watch: {
    item: {
      deep: true,
      handler (newV, oldV) {
        console.log(newV, 'itemdeep');
        this.itemChild = newV
        this.videoSrc()
      }
    },
    // 配置自定义
    setting: function (newV, oldV) {
      console.log(newV, 'item');
      this.playSetting = newV
    }
  },
  created() {
  },
  mounted() {
    if (this.setting) {
      console.log('object1');
      this.playSetting = this.setting
    }
    if (this.item) {
      this.itemChild = this.item
      this.$nextTick(() => {
        this.playSetting.id= 'avideo'+this.itemChild.id
        this.playSetting.source= this.itemChild.source
        if (this.itemChild.width) {
          this.playSetting.width= this.itemChild.width
        }
        if (this.itemChild.height) {
          this.playSetting.height= this.itemChild.height
        }
        if (this.itemChild.isLive) {
          this.playSetting.isLive = this.itemChild.isLive
        }
        if (this.itemChild.autoplay) {
          this.playSetting.autoplay = this.itemChild.autoplay
        }
        if (this.itemChild.skinLayout) {
          this.playSetting.skinLayout= this.itemChild.skinLayout
        }
        this.$set(this, 'playSetting', JSON.parse(JSON.stringify(this.playSetting)))
        this.videoSrc()
      })
    }
    let fullScreen = false
    if (this.itemChild.autoplay) {
      this.setInter = setInterval(() => {
        console.log('play',this.player);
        // console.log('fullscreenService',this.player.fullscreenService);
        console.log('webkitDisplayingFullscreen',this.player.tag.webkitDisplayingFullscreen);
        if (fullScreen == true && !this.player.tag.webkitDisplayingFullscreen) {
          // 退出屏幕
          console.log('退出屏幕');
          // console.log(document);
          if (this.player) {
            if (this.player.tag.paused) {
              // alert('被暂停自动播放');
              this.$nextTick(()=>{
                let a = document.getElementsByTagName('video')[0]
                console.log(a,'a');
                a.play()
              })
              // this.player.play()
            }
          }
        }
        fullScreen = this.player.tag.webkitDisplayingFullscreen
        this.$nextTick(()=>{
          if (!fullScreen && this.player) {
            console.log(this.player.tag.paused,'this.player.tag.paused');
            if (this.player.tag.paused == true) {
              this.$nextTick(()=>{
                let a = document.getElementsByTagName('video')[0]
                console.log(a,'1');
                // a.play()
                // this.player.play()
              })
            }
          }
        })
      }, 1000)
    }
  },
  beforedestroy() {
    // this.onPlayerEnded();
    if (this.player !== null) {
      this.player.pause();
      this.player = null;
    }
    if (this.itemChild.autoplay) {
      clearInterval(this.setInter)
    }
  },
  data() {
    return {
      itemChild:{
        id:'',
        source:'',
      },
      player: null,
      playSetting: {
        id: "avideo",
        width: "100%",
        height: "100px",
        autoplay: false,
        // encryptType: 1,
        // vid: this.question.selfVideoUrl,
        // playauth: this.question.selfVideoUrl,
        source: '',
        cover: "",
        isLive: false,
        rePlay: false,
        playsinline: true,
        preload: true,
        controlBarVisibility: "hover",
        useH5Prism: true
      },
      settingChild: {},
    }
  },
  methods: {
    videoSrc(ele, callback) {
      // 开始进来调用的视频接口
      if (this.player) this.player.dispose();
      let that = this
      console.log('ssss');
      console.log(JSON.parse(JSON.stringify(this.playSetting)));
      this.player = new Aliplayer(this.playSetting,
        function (player) {
          console.log("播放器创建好了。",player);
          if (that.itemChild.autoplay) {
            // player.on('autoplay', function(data) {
            //   if (data.paramData) {
            //     // 可以自动播放
            //     // 隐藏提示
            //     // alert('可以自动播放')
            //   } else {
            //     // 不可以自动播放
            //     // 显示提示用户点击播放
            //     // alert('不可以自动播放')
            //     player.mute();
            //   }
            // })
            // document.on('WeixinJSBridgeReady',()=>{ 
            //   if(player.tag)
            //   {
            //     player.tag.play();
            //   }
            // });
            console.log(player.getIsFullScreen(),'autoplay');
          }
        }
      );
    },
  },
}
</script>
<style lang="scss" scoped>
</style>